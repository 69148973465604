/* Webfont: ATSurt-Regular */@font-face {
    font-family: 'ATSurt';
    src: url('../fonts/ATSurt-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ATSurt-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ATSurt-Regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ATSurt-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ATSurt-Regular.svg#ATSurt-Regular') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ATSurt-Light */@font-face {
    font-family: 'ATSurtLight';
    src: url('../fonts/ATSurt-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ATSurt-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ATSurt-Light.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ATSurt-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ATSurt-Light.svg#ATSurt-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ATSurt-SemiBold */@font-face {
    font-family: 'ATSurtSemiBold';
    src: url('../fonts/ATSurt-SemiBold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ATSurt-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ATSurt-SemiBold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ATSurt-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/ATSurt-SemiBold.svg#ATSurt-SemiBold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: SaolDisplay-Medium */@font-face {
    font-family: 'SaolDisplayMedium';
    src: url('../fonts/SaolDisplay-Medium.eot'); /* IE9 Compat Modes */
    src: url('../fonts/SaolDisplay-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/SaolDisplay-Medium.woff') format('woff'), /* Modern Browsers */
    url('../fonts/SaolDisplay-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/SaolDisplay-Medium.svg#SaolDisplay-Medium') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: SaolDisplay-SemiboldItalic */@font-face {
    font-family: 'SaolDisplaySemibold';
    src: url('../fonts/SaolDisplay-SemiboldItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/SaolDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/SaolDisplay-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/SaolDisplay-SemiboldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/SaolDisplay-SemiboldItalic.svg#SaolDisplay-SemiboldItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

@mixin at-surf-regular($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ATSurt', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin at-surf-light($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ATSurtLight', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin at-surf-semi-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'ATSurtSemiBold', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin saol-display-medium($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'SaolDisplayMedium', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin saol-display-semi-bold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'SaolDisplaySemibold', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}
