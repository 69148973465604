.button-rect {
    display: inline-block;

    a {
        position: relative;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $beige;
        border: 1px solid $beige;
        padding: 0 40px;
        color: white;
        transition: color 0.4s $easeOutQuart 0.15s;

        &:before {
            content: "";
            @include full-absolute;
            background-color: darken($beige, 10%);
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 0.4s $easeInOutQuart;
            z-index: 1;
        }

        html.desktop & {
            &:hover {
                color: white;

                &:before {
                    transform: scaleX(1);
                }
            }
        }

        .text {
            position: relative;
            @include at-surf-semi-bold(14px, 14px, 0.05em);
            z-index: 2;
        }
    }

    &.line {

        a {
            background-color: transparent;
            color: $beige;
        }
    }
}

.button-underline {

    a,
    .text {
        cursor: pointer;
        position: relative;
        @include at-surf-semi-bold(14px);
        line-height: 1;
        padding-bottom: 4px;
        color: $beige;
        border-bottom: 2px solid $beige;
        transition: color 0.4s $easeOutQuart;

        &:before {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: darken($beige, 10%);
            z-index: 2;
            transform-origin: 0 0;
            transform: scaleX(0);
            transition: transform 0.4s $easeInOutQuart;
        }

        html.desktop & {

            &:hover {
                color: darken($beige, 10%);

                &:before {
                    transform: scaleX(1);
                }
            }
        }
    }

    &.disabled {
        display: none;
    }
}