#header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 80px;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .header__left {
        padding-top: 56px;

        ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            li {
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    position: relative;
                    display: block;
                    @include at-surf-semi-bold(14px, 14px, 0.05em);
                    color: white;

                    svg {
                        pointer-events: none;
                        opacity: 0;
                        @include center-xy;
                        width: 110px;
                        height: 44px;
                        transition: opacity 0.3s $easeOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            svg {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .header__logo {
        @include center-x;
        top: 30px;

        a {
            display: block;
        }

        svg {
            width: 260px;
            height: 103px;
        }
    }

    .header__right {
        padding-top: 38px;
        margin-left: auto;
    }
}

@media screen and (max-width: $mobile) {
    #header {

        .header__left {
            display: none;
        }

        .header__logo {

            svg {
                width: 200px;
                height: auto;
            }
        }

        .header__right {
            display: none;
        }
    }
}