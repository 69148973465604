#home {

    section {
        position: relative;
    }

    /*HERO*/
    .section-hero {
        height: 100vh;
        color: white;

        .section__background {
            @include full-absolute;
            z-index: 1;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .grid,
        .row {
            z-index: 2;
            height: 100%;
        }

        .row {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        h1 {
            @include saol-display-semi-bold(140px);
            line-height: 1;
        }

        h2 {
            @include at-surf-light(50px);
            line-height: 1;
        }

        .description {
            @include at-surf-light(20px, 30px, 0);
            max-width: 445px;
            margin: 60px auto 0 auto;
        }

        .button-rect {
            display: none;
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .column-8 {
            @include center-x;
            bottom: 0;
            padding: 20px 40px;
            background-color: $purple;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .title {
                @include at-surf-light(30px, 34px);
                //width: 60%;
            }

            .sep {
                height: 1px;
                width: 60px;
                background-color: $beige;
                margin: 0 60px;
            }

            .date {
                width: 160px;

                .date__legend {
                    @include at-surf-semi-bold(14px, 18px, 0.05em);
                    color: $beige;
                    text-transform: uppercase;
                }

                .date__nb {
                    margin-top: 10px;
                    @include saol-display-semi-bold(30px, 30px);
                }
            }
        }
    }

    .section-slider {
        z-index: 2;
        padding: 120px 0 80px 0;

        .navigation {
            position: absolute;
            top: 0;
            @include set-grid-prop(left, 1, 1);
            @include saol-display-semi-bold(30px, 30px);
            color: $purple;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .sep {
                width: 40px;
                height: 1px;
                background-color: $purple;
                margin: 0 20px;
            }
        }

        .column-2 {
            padding-top: 220px;
            display: flex;
            align-items: center;
            justify-content: center;

            .slide-arrow {
                position: relative;
                cursor: pointer;
                width: 70px;
                height: 70px;
                background-color: transparent;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before {
                    content: "";
                    @include center-xy;
                    background-color: $beige;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    opacity: 0;
                    transition: transform 0.4s $easeInOutQuart, opacity 0.4s $easeOutQuart;
                }

                html.desktop & {
                    &:hover {

                        &:before {
                            transform: translate(-50%, -50%) scale(1);
                            opacity: 1;
                        }

                        .arrow {
                            fill: white;
                        }
                    }
                }

                .arrow {
                    width: 31px;
                    height: 18px;
                    fill: $purple;
                    transition: fill 0.3s $easeOutQuart 0.15s;
                }

                .circle {
                    position: absolute;
                    top: 0;
                    left: 6px;
                    z-index: 1;
                    width: 64px;
                    height: 70px;
                }

                &.prev {

                    .arrow {
                        transform: rotate(-180deg);
                    }

                    .circle {
                        transform: rotate(-180deg);
                        left: 0;
                    }
                }

                &.next {
                    transform: translate(0, 28px);
                }
            }
        }

        .column-4 {
            padding-top: 70px;
            @include set-grid-prop(margin-left, 1, 0);

            .slider-item {
                position: absolute;
                top: 70px;
                left: 0;
                width: 100%;
                @include autoAlpha(0);

                &:first-child {
                    @include autoAlpha(1);
                }

                .slider-item__title {
                    @include saol-display-medium(60px, 60px);
                    color: $purple;
                    text-transform: uppercase;
                }

                .slider-item__subtitle {
                    @include at-surf-light(30px, 30px);
                    color: $beige;
                }

                .slider-item__content {
                    margin-top: 40px;

                    p {
                        margin-top: 30px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }

                .slider-item__button {
                    margin-top: 40px;
                }
            }
        }

        .column-6 {
            @include set-grid-prop(margin-left, 1, 0);
            padding-top: 100px;
            min-height: 664px;

            .shape {
                position: relative;
                height: auto;
                max-width: 100%;
                margin: 0 auto;
                z-index: 1;
            }

            .slider-item {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                z-index: 10;
                @include autoAlpha(0);

                &:first-child {
                    @include autoAlpha(1);
                }

                &.s-3 {
                    margin-top: 100px;
                }
            }
        }
    }

    /*PORTRAIT*/
    .section-portrait {
        padding: 140px 0 100px 0;
        background: $purple url('../images/common/background.jpg') no-repeat center center;
        background-size: cover;

        .r-1 {

            .column-12 {
                @include set-grid-prop(margin-left, 2, 2);
            }

            h2 {
                color: white;
                @include saol-display-medium(60px, 60px);
                text-transform: uppercase;
            }
        }

        .r-2 {
            margin-top: 60px;

            .column-4 {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }

            .column-6 {
                @include set-grid-prop(margin-left, 2, 1);

                .title {
                    text-align: right;

                    .name {
                        @include saol-display-semi-bold(80px, 80px);
                        color: white;
                    }

                    .function {
                        margin-top: 6px;
                        @include at-surf-semi-bold(14px, 16px);
                        color: $beige;
                        text-transform: uppercase;
                    }
                }

                .cv {
                    margin-top: 50px;
                    text-align: left;

                    .cv-item {
                        margin-top: 30px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;

                        &:first-child {
                            margin-top: 0;
                        }

                        .cv-item__icon {
                            flex: 0 0 auto;

                            svg {
                                width: 70px;
                                height: 70px;
                            }
                        }

                        .cv-item__content {
                            padding-top: 18px;
                            margin-left: 40px;

                            .cv-item__content-title {
                                @include at-surf-light(30px, 40px);
                                color: $beige;
                            }

                            ul {
                                margin-top: 20px;

                                li {
                                    position: relative;
                                    @include at-surf-light(16px, 30px);
                                    color: white;

                                    &:before {
                                        content: "";
                                        position: absolute;
                                        left: -16px;
                                        top: 13px;
                                        height: 1px;
                                        width: 10px;
                                        background-color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .r-3 {
            margin-top: 100px;
            position: relative;

            .column-4 {
                @include set-grid-prop(margin-left, 1, 1);

                .quote {
                    @include saol-display-semi-bold(50px, 60px);
                    color: white;
                    margin-top: 20px;
                }
            }

            .column-6 {
                position: absolute;
                top: 0;
                @include set-grid-prop(right, 1, 1);
            }
        }
    }

    /*OFFERS*/
    .section-offers {
        padding-top: 140px;
        padding-bottom: 100px;

        .r-1 {
            @include saol-display-medium(60px, 60px);
            color: $purple;
            text-transform: uppercase;

            .column-6 {
                @include set-grid-prop(margin-left, 2, 2);
            }
        }

        .r-2 {
            margin-top: 80px;

            .offer-item {
                margin-top: 20px;
                @include set-grid-prop(margin-left, 2, 2);

                &:first-child {
                    margin-top: 0;
                }

                &.o-1 {
                    .offer-item__top {
                        background-color: #F8F5F5;
                    }

                    .offer-item__bottom {
                        background-color: rgba(244, 239, 239, 1);
                    }
                }

                &.o-2 {
                    .offer-item__top {
                        background-color: #FBEFD9;
                    }

                    .offer-item__bottom {
                        background-color: rgba(247, 233, 211, 1);
                    }
                }

                &.o-3 {
                    .offer-item__top {
                        background-color: #FFEAE0;
                    }

                    .offer-item__bottom {
                        background-color: rgba(251, 228, 218, 1);
                    }
                }

                &.o-4 {
                    .offer-item__top {
                        background-color: #EEECF4;
                    }

                    .offer-item__bottom {
                        background-color: rgba(234, 230, 238, 1);
                    }
                }

                .offer-item__top {
                    padding: 30px 6%;
                    display: flex;
                    align-items: center;

                    .offer-item__icon {
                        flex: 1 0 auto;

                        svg {
                            width: 70px;
                            height: 70px;
                        }
                    }

                    .offer-item__content {
                        margin-left: 40px;

                        .title {
                            @include at-surf-light(30px, 40px);
                            color: $beige;
                        }

                        .description {
                            margin-top: 20px;
                            max-width: 390px;
                        }

                        .buttons {
                            margin-top: 20px;
                        }
                    }

                    .offer-item__sep {
                        width: 40px;
                        height: 1px;
                        background-color: $beige;
                        margin: 0 40px;
                    }

                    .offer-item__price {
                        flex: 0 0 auto;
                        text-align: center;

                        .legend {
                            @include at-surf-semi-bold(14px, 16px);
                            color: $beige;
                            text-transform: uppercase;

                            + .nb {
                                margin-top: 10px;
                            }
                        }

                        .nb {
                            @include saol-display-semi-bold(30px, 30px);
                            color: $purple;
                        }
                    }
                }

                .offer-item__bottom {
                    overflow: hidden;
                    height: 0;

                    .offer-item__bottom-inner {
                        position: relative;
                        padding: 30px calc(6% + 30px) 30px 6%;
                    }

                    .close {
                        cursor: pointer;
                        position: absolute;
                        right: 6%;
                        top: 30px;

                        svg {
                            width: 16px;
                            height: 16px;
                            fill: $purple;
                        }
                    }

                    .text-content {
                        margin-left: 110px;
                        max-width: 440px;

                        p {
                            margin-top: 20px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }

                        ul {
                            margin-top: 20px;
                            color: $beige;

                            &:first-child {
                                margin-top: 0;
                            }

                            li {
                                padding-left: 60px;

                                &:before {
                                    position: absolute;
                                    left: 0;
                                    top: 10px;
                                    content: "";
                                    background-color: $beige;
                                    width: 40px;
                                    height: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .r-3 {
            margin-top: 50px;

            .column-12 {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .button-rect {
                margin: 0 auto;
            }


        }
    }

    /*GALLERY*/
    .section-gallery {
        background: rgb(248,245,245);
        background: linear-gradient(0deg, rgba(248,245,245,0) 0%, rgba(248,245,245,1) 100%);
        padding: 140px 0;

        .r-1 {

            .column-8 {
                @include set-grid-prop(margin-left, 2, 2);
            }

            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 70px;
                height: 70px;
                transform: translate(-34px, -24px);
            }

            h2 {
                @include saol-display-medium(60px, 60px);
                color: $purple;
            }
        }

        .r-2 {
            margin-top: 70px;

            .img-responsive {
                margin-top: 40px;
            }

            .column-4 {
                @include set-grid-prop(margin-left, 1, 0);

                &:first-child {
                    margin-left: 0;
                }

                &.c-1,
                &.c-3 {
                    margin-top: 170px;
                }
            }
        }
    }

}

@media screen and (max-width: $mobile) {
    #home {

        /*HERO*/
        .section-hero {
            height: auto;
            padding-top: 180px;

            &.no-sales {
                padding-bottom: 60px;
            }

            h1 {
                font-size: 60px;
            }

            h2 {
                font-size: 30px;
            }

            .description {
                margin-top: 20px;
                font-size: 14px;
                line-height: 24px;
            }

            .button-rect {
                display: block;
            }

            .column-8 {
                position: relative;
                width: 100%;
                left: auto;
                bottom: auto;
                transform: translate(0, 0);
                flex-direction: column;

                .title {
                    font-size: 24px;
                    line-height: 30px;
                }

                .sep {
                    margin: 30px 0;
                }
            }
        }

        /*SLIDER*/
        .section-slider {
            padding: 60px 0 40px 0;

            .navigation {
                display: none;
            }

            .row {
                display: flex;
                flex-direction: column;
            }

            .column-2 {
                order: 2;
                padding-top: 0;

                .slide-arrow {

                    &.next {
                        transform: translate(0, 0);
                        margin-left: auto;
                    }
                }
            }

            .column-4 {
                padding-top: 40px;
                order: 3;

                .slider-item {
                    top: auto;
                    left: auto;
                    //position: relative;
                    //display: none;

                    .slider-item__title {
                        font-size: 40px;
                        line-height: 1;
                    }

                    .slider-item__subtitle {
                        font-size: 24px;
                        line-height: 30px;
                    }

                    .slider-item__content {
                        margin-top: 30px;
                    }

                    .slider-item__button {
                        margin-top: 30px;
                    }

                    &:first-child {
                        display: block;
                    }
                }
            }

            .column-6 {
                order: 1;
                min-height: 400px;
                padding-top: 60px;

                .slider-item {
                    &.s-3 {
                        margin-top: 60px;
                    }
                }
            }
        }

        .section-portrait {
            padding: 60px 0 60px 0;

            .r-1 {

                h2 {
                    font-size: 40px;
                    line-height: 40px;
                }
            }

            .r-2 {

                .column-4 {
                    align-items: flex-start;
                    justify-content: flex-start;

                    img {
                        width: 150px;
                        height: auto;
                    }
                }

                .column-6 {
                    margin-top: -150px;

                    .title {
                        text-align: left;
                        padding-left: 130px;

                        .name {
                            font-size: 40px;
                            line-height: 40px;
                        }

                        .function {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    .cv {
                        margin-top: 90px;

                        .cv-item {
                            margin-top: 40px;
                            flex-direction: column;

                            .cv-item__content {
                                padding-top: 23px;
                                margin-left: 0;

                                .cv-item__content-title {
                                    font-size: 24px;
                                    line-height: 30px;
                                }

                                ul {
                                    margin-top: 20px;
                                    padding-left: 16px;

                                    li {
                                        font-size: 14px;
                                        line-height: 28px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .r-3 {
                margin-top: 50px;

                .column-4 {

                    .quote {
                        font-size: 40px;
                        line-height: 50px;
                    }
                }

                .column-6 {
                    position: relative;
                    right: auto;
                    top: auto;
                    margin-top: 40px;
                }
            }
        }

        /*SECTION OFFERS*/
        .section-offers {
            padding: 60px 0;

            .r-1 {

                h2 {
                    font-size: 40px;
                    line-height: 1;
                }
            }

            .r-2 {
                margin-top: 40px;

                .offer-item {

                    .offer-item__top {
                        flex-direction: column;
                        padding: 30px;

                        .offer-item__content {
                            margin-left: 0;
                            text-align: center;
                            margin-top: 20px;
                        }

                        .offer-item__sep {
                            display: none;
                        }

                        .offer-item__price {
                            margin-top: 40px;
                        }
                    }

                    .offer-item__bottom {

                        .text-content {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        /*GALLERY*/
        .section-gallery {
            padding: 60px 0;

            .r-1 {

                svg {
                    display: none;
                }

                h2 {
                    font-size: 38px;
                    line-height: 1;
                }
            }

            .r-2 {
                margin-top: 40px;

                .column-4 {
                    &.c-1 {
                        margin-top: 0;
                    }

                    &.c-3 {
                        margin-top: 40px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 374px) {
    #home .section-slider .column-6 {
        min-height: 360px;
    }
}


