#footer {

    .footer__top {
        background-color: #F8F5F5;
        padding: 50px 0;
    }

    .footer_bottom {
        background-color: $beige;
        padding: 20px 0;

        .column-12 {
            @include at-surf-regular(14px, 14px);
            color: white;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .credit {
                margin-left: auto;
            }

            a {
                border-bottom: 1px solid white;
                padding-bottom: 2px;
            }
        }
    }

    .c-1,
    .c-3 {
        padding-top: 30px;
    }

    .c-2 {
        @include set-grid-prop(margin-left, 2, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        a {
            display: block;
        }

        svg {
            position: relative;
            width: 260px;
            height: 128px;
            margin: 0 auto;
        }

        .legend {
            font-size: 12px;
            line-height: 1;
            margin: 30px 0 10px 0;
        }
    }

    .c-3 {
        @include set-grid-prop(margin-left, 2, 1);
        text-align: right;
    }

    .title {
        @include at-surf-light(30px, 30px);
        color: $beige;
        white-space: nowrap;
    }

    .content {
        margin-top: 30px;
    }

    .links {
        margin-top: 40px;

        a {
            position: relative;
            @include at-surf-semi-bold(14px);
            line-height: 1;
            padding-bottom: 4px;
            color: $beige;
            border-bottom: 2px solid $beige;
            transition: color 0.4s $easeOutQuart;

            &:before {
                content: "";
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: darken($beige, 10%);
                z-index: 2;
                transform-origin: 0 0;
                transform: scaleX(0);
                transition: transform 0.4s $easeInOutQuart;
            }

            html.desktop & {

                &:hover {
                    color: darken($beige, 10%);

                    &:before {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    #footer {

        .footer__top {
            padding: 40px 0;

            .row {
                display: flex;
                flex-direction: column;
                align-items: center;

                .c-2 {
                    order: 1;
                    padding-top: 0;

                    svg {
                        width: 210px;
                        height: auto;
                    }
                }

                .c-1 {
                    order: 2;
                    margin-top: 50px;
                }

                .c-3 {
                    margin-top: 40px;
                    order: 3;
                    text-align: left;
                }

                .title {
                    font-size: 24px;
                }

                .content {
                    margin-top: 20px;
                    font-size: 14px;
                    line-height: 24px;
                }

                .links {
                    margin-top: 20px;
                }
            }
        }
    }
}