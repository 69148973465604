html {
    background-color: white;
}

body {
    position: relative;
    background-color: white;
    font-size: 100%;
    min-height: 100%;
    @include at-surf-regular(16px, 24px);
    color: #171717;
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

#preload {
    @include full-fixed;
    z-index: 10000;

    .l-1 {
        @include full-absolute;
        background-color: darken($purple, 10%);
        transform-origin: 0 0;
        z-index: 1;
    }

    .l-2 {
        @include full-absolute;
        background-color: $purple;
        transform-origin: 0 0;
        z-index: 2;
    }
}

img,
svg {
    display: block;
}

@media screen and (max-width: $mobile) {
    body {
        font-size: 14px;
        line-height: 24px;
    }
}