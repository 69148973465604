/*********************
BASE
*********************/
@import "base/normalize";
@import "base/reset";
@import "base/ress";

/*********************
HELPERS
*********************/
@import "helpers/functions";
@import "helpers/easing";
@import "helpers/helpers";
/*@import "helpers/locomotive-scroll";*/

/*********************
COMMON
*********************/
@import "common/grid";
@import "common/fonts";
@import "common/colors";
@import "common/buttons";
@import "common/animations";
@import "common/form";
@import "common/content";

/*********************
HEADER
*********************/
@import "components/header";

/*********************
FOOTER
*********************/
@import "components/footer";

/*********************
PAGE
*********************/
@import "layouts/page";

/*********************
PAGES
*********************/
@import "pages/home";
@import "pages/common";